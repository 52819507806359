@import "../../styles/variables.scss";

@mixin button-bg($bg) {
  background: $bg;

  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }

  &:active {
    background: darken($bg, 25%);
  }
}

@mixin letter($color) {
  color: $color;

  &:hover {
    color: darken($color, 5%);
    transition: all 0.3s ease;
  }
}

@mixin border($color) {
  border: 2px solid $color;
}

.btn {
  cursor: pointer;
  text-decoration: none;
  font-family: $primary_font;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.2px;
  letter-spacing: 1.33px;
  text-align: center;
  padding: 0.2em;
  text-transform: capitalize !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.7em;

  &__icon {
    font-size: 22px;
    text-align: center;
    width: 22px;
    height: 22px;
  }
}

.btn-none {
  display: none;
}

.btn-tertiary {
  @include button-bg($transparent);
  @include letter($theme_tertiary);
  @include border($transparent);
}

.btn-secondary {
  @include button-bg($secondary_text);
  @include letter($theme_primary);
  @include border($theme_primary);
}

.btn-primary {
  @include button-bg($theme_primary);
  @include letter($white);
  @include border($theme_primary);
}

.btn-primary-white {
  @include button-bg($white);
  @include letter($theme_primary);
  @include border($theme_primary);
}

.btn-secondary-white {
  @include button-bg($white);
  @include letter($theme_primary);
  @include border($white);
}

.btn-ghost {
  @include button-bg(transparent);
  @include letter($white);
  @include border($white);
}

.btn-ghost-variant {
  @include button-bg($white);
  @include letter($theme_secondary);
  @include border($theme_secondary);
}

.btn-tertiary-white {
  background: $gray-lighten-0;
  @include letter($primary);
  @include border($primary);
}

.btn-grey {
  @include button-bg($gray_lighten-1);
  @include letter($gray_darken);
  @include border($gray_lighten-1);
}

.btn-alert {
  @include button-bg($theme_secondary);
  @include letter($white);
  @include border($theme_secondary);
}