@import "../../../styles/variables.scss";

.navbar {
  background: $theme_primary;
  color: $white;
  width: 100%;
  height: 50px;
  padding: 2rem;
  display: flex;
  align-items: center;
  &__content {
    width: 90%;
    margin: 0 auto;
  }
}
.wrapper {
  height: 69vh;
  margin: 0 auto;
  display: flex;
  padding: 30px 0;
  p {
    margin: 10px 0;
  }
}
