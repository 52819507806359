@import "./../../styles/mixins/mixin.scss";
@import "./../../styles/variables.scss";

.content {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  @include breakpoint(xs) {
    height: 700px;
  }

  &__slider {
    display: flex;
    flex-wrap: nowrap;

    @include breakpoint(xs) {
      height: 100%;
    }

    @include breakpoint(sm) {
      height: 100%;
    }

    &__slide {
      min-width: 100%;
      overflow: hidden;
      transition: 0.3s ease all;
      position: relative;

      @include breakpoint(xs) {
        max-height: 100%;
      }

      @include breakpoint(sm) {
        min-height: 600px;
        max-height: 100%;
      }

      @include breakpoint(md) {
        min-height: 700px;
        max-height: 100%;
      }

      @include breakpoint(lg) {
        min-width: 100%;
        overflow: hidden;
        transition: 0.3s ease all;
        min-height: 588px;
        max-height: 100%;
        position: relative;
      }

      @include breakpoint(xl) {
        max-height: 588px;
      }

      img {
        width: 100%;
        height: 100%;
        vertical-align: top;
        object-fit: cover;
      }

      &__text {
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        padding: 1em;

        @include breakpoint(lg) {
          width: 50%;
        }

        @include breakpoint(md) {
          width: 50% !important;
          top: 6.5em !important;
          left: 5em !important;
          height: auto !important;
        }

        @include breakpoint(sm) {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;

          .row .flex-sm-4 {
            flex: 0 0 35.333333% !important;
          }
        }
      }
    }
  }
}

.controls {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @include breakpoint(xs) {
    height: 10%;
    bottom: 20px;
    top: auto;
  }

  button {
    pointer-events: all;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    width: 50px;
    height: 100%;
    text-align: center;
    position: absolute;
    transition: 0.3s ease all;
    color: #fff;
    font-size: 50px;

    &:hover {
      background: rgba($color: #000000, $alpha: 0.2);
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right {
    right: 0;
  }

  &__left {
    left: 0;
  }
}

.margin {
  margin: 0px !important
}