@import "../../../src/styles/variables.scss";
.spin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__center {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: $primary;
    }
  }
}

.wrapper__loader {
  width: 120px;
}
