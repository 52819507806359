@import "../../../styles/variables.scss";
@import "../../../styles/mixins/mixin.scss";

.faq {
  padding-top: 50px;
  padding-bottom: 109px;
  @include breakpoint(xs) {
    padding: 30px 0px;
  }
  &__question {
    margin-bottom: 0.125rem;
    @include breakpoint(xs) {
      margin: 0;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 4rem;
    align-items: baseline;
    justify-items: center;
    @include breakpoint(xs) {
      grid-template-columns: 1fr;
    }
    &__qa {
      width: 100%;
      @include breakpoint(xs) {
        display: none;
      }
      &__xs {
        display: none;
        @include breakpoint(xs) {
          display: block;
        }
        &__title {
          font-size: 26px !important;
        }
      }
    }
    &__filter{
      width: 100%;
    }
  }
}
