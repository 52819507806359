@import "./../../../styles/variables.scss";
.wrapper__input {
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    width: 100%;
    height: 56px;
    padding: 0 2.75rem 0 1rem;
    outline: 0;
    font-size: 1rem;
    border: 1px solid $input-color-normal;
    border-radius: 6px;
  }

  textarea {
    width: 100%;
    padding: 1rem;
    outline: 0;
    font-size: 1rem;
    font-family: $primary_font;
    border: 1px solid #ecf0f1;
    box-shadow: 0px 2px 20px #ecf0f1;
    border-radius: 20px;
  }

  select {
    width: 100%;
    padding: 1rem;
    outline: 0;
    font-size: 1rem;
    height: 56px;
    border: 1px solid #ecf0f1;
    box-shadow: 0px 2px 20px #ecf0f1;
    border-radius: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-position: calc(100% - 12px) center !important;
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
      no-repeat #fff;
  }
  select::after {
    content: "";
    position: absolute;
    right: 0;
    top: 20px;
    width: 0;
    height: 0;
    padding: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid cornflowerblue;
  }

  label {
    padding: 0 1rem;
    color: #53565a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    position: absolute;
    transform: translate(0, 18px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    cursor: text;
  }
  &:focus-within {
    label {
      transform: translate(0, -20px) scale(0.75);
      color: $input-color-warning;
      font-weight: 500;
      font-size: 1rem;
    }
    input {
      border: 2px solid $input-color-warning;
    }
  }
}

.active {
  transform: translate(0, -20px) scale(0.75) !important;
  color: $theme_primary !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.error {
  font-size: 0.75rem;
  color: $input-color-danger !important;
  &__input__error {
    input, textarea {
      border: 2px solid $input-color-danger;
    }
  }
  p {
    margin: 0rem 1rem 0;
  }
}

.success {
  color: #48D2A0 !important;
}

.required {
  font-size: 12px;
  color: $gray_lighten-2;
  margin: 0.5rem 1rem 0;
}

.wrapper__icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  &__error {
    color: $input-color-danger;
  }
}

.textarea_wrapper__icon {
  position: absolute;
  right: 1rem;
  top: 2rem;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.38);
  &__error {
    color: #e4002b;
  }
}
