@import "../../../styles/variables.scss";
@import "../../../styles/mixins/mixin.scss";

.margin_left {
  margin-left: 5%;
  .wrapper_subscriptions{
    display: flex;
    padding: 0 30px;
  }
}

.wrapper_subscriptions__wide {
  &__display{
    display: block;
  }
  @include breakpoint(xsl) {
    &__display{
      display: none;
    }
  }
}

.wrapper_subscriptions__small {
  &__display{
    display: none;
  }
  @include breakpoint(xsl) {
    &__display{
      display: block;
    }
  }
}