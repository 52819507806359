.modal {
    background-color: #00000094;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    z-index: 9999;

    &__logo {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 18px;

        &__img {
            padding-right: 20px;
        }
    }


    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        cursor: pointer;
        z-index: 9999;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.9);
        backdrop-filter: blur(1px);
        z-index: 9998;
    }

    &__content {
        width: 45%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: white;
        backdrop-filter: blur(1px);
        z-index: 9999;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
        padding-bottom: 60px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease transform;
        transform: translate(0);

        @media (min-width: 801px) and (max-width: 1224px) {
            width: 50%;
        }

        @media (max-width: 800px) {
            width: 90%;
        }
        @media (max-width: 600px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    &__content--open {
        transform: translate(0, -100%);
    }

    &__content--close {
        transform: translate(0, 100%);
    }

}

.MuiModal-root, .MuiDialog-root, div[role=presentation] {
    z-index: 6000 !important;
}