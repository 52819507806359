@import "../../../styles/variables.scss";

.return {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 64px;

  div {
    color: $theme_secondary;
    font-size: 20px;
  }

  span {
    font-family: $primary_font;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 1.33333px;
    text-transform: uppercase;
    color: $theme_secondary;
  }
}
.back__btn {
  position: absolute;
  top: -42px;
  left: 30px;
  
  @include breakpoint(xs) {
    left: 0;
  }
  
  @include breakpoint(sm) {
    left: 0;
  }

  @include breakpoint(lg) {
    left: 30px;
  }
}

.banner_img {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100px;
    position: relative;

    &__layout {
      position: absolute;
      width: 100%;
      z-index: 1;
      background: $theme_primary;
    }
  
    &__backTo {
      width: 100%;
      padding: 6% 0 2%;
  
      &__btn {
        display: flex;
        align-items: center;
        color: $white;
      }
      &__label {
        padding: 0 0.5rem;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 1.33px;
        text-transform: uppercase;
      }
    }
}