@import "./../../../styles/variables.scss";

.input-data {
    color: #53565A;
    display: flex;

    .text {
        font-family: $primary_font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        margin-right: 5px;
    }

    .btn-input {
        border: none;
        background: transparent;
        color: #53565A;
        font-size: 16px;
        cursor: pointer;
        margin-right: 5px;
        padding: 0;
    }
}