@use 'sass:math';
@import "./tools/tools.scss";
@import "./mixins/mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;600&display=swap');

/* ------------------------------ 
    FONTS 
------------------------------ */

@font-face {
  font-family: "Century Gothic";
  src: url("../static/fonts/century/GOTHIC.TTF") format('truetype') font-weight-normal,
    url("../static/fonts/century/GOTHICB.TTF") format('truetype') font-weight-bold,
    url("../static/fonts/century/GOTHICI.TTF") format('truetype') font-weight-italic,
    url("../static/fonts/century/GOTHICBI.TTF") format('truetype') font-italic font-weight-bold;
}

@font-face {
  font-family: "Trebuchet MS";
  src: url("../static/fonts/trebuchet/Trebuchet-MS.ttf") format('truetype') font-weight-normal,
    url("../static/fonts/trebuchet/Trebuchet-MS-Italic.ttf") format('truetype') font-weight-italic;
}

@font-face {
  font-family: "Fira Sans";
  src: url("../static/fonts/fira/FiraSans-Regular.otf") format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: "Fira Sans";
  src: url("../static/fonts/fira/FiraSans-Italic.otf") format('truetype');
  font-weight: italic;
}

@font-face {
  font-family: "Fira Sans";
  src: url("../static/fonts/fira/FiraSans-Bold.otf") format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: "Fira Sans";
  src: url("../static/fonts/fira/FiraSans-BoldItalic.otf") format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Fira Sans";
  src: url("../static/fonts/fira/FiraSans-Light.otf") format('truetype');
  font-weight: lighter;
  /* Opcional: cambia a 'lighter' si es una fuente más ligera */
}

@font-face {
  font-family: "Fira Sans";
  src: url("../static/fonts/fira/FiraSans-LightItalic.otf") format('truetype');
  font-weight: lighter;
  /* Opcional: cambia a 'lighter' si es una fuente más ligera */
  font-style: italic;
}

@font-face {
  font-family: "Fira Sans";
  src: url("../static/fonts/fira/FiraSans-Medium.otf") format('truetype');
  font-weight: 500;
  /* Opcional: puedes ajustar el peso según tus necesidades */
}

@font-face {
  font-family: "Fira Sans";
  src: url("../static/fonts/fira/FiraSans-MediumItalic.otf") format('truetype');
  font-weight: 500;
  /* Opcional: puedes ajustar el peso según tus necesidades */
  font-style: italic;
}


// $primary_font: 'Century Gothic', sans-serif;
$primary_font: 'Fira Sans', sans-serif;
$secondary_font: "Helvetica Neue", Helvetica, Arial, sans-serif;

/* ------------------------------ 
    COLORS 
------------------------------ */
// Palete colors
$red-lighen-0: #731017;
$red-lighen-1: #AC1823;
$red-lighen-2: #E5202E;
$red-lighen-3: #F29097;
$red-lighen-4: #FAD2D5;

$blue-lighten-0: #007AC3;
$blue-lighten-1: #005C92;
$blue-lighten-2: #007AC3;
$blue-lighten-3: #409BD2;
$blue-lighten-4: #A6D0EA;

$dark-lighten-0: #191919;
$dark_lighten-1: #005C92;
$dark_lighten-2: #939393;
$dark_lighten-3: #DADADA;
$dark_lighten-4: #EDEDED;

$gray_lighten-0: #878990;
$gray_lighten-1: #8b92b1;
$gray_lighten-2: #8189a9;
$gray_lighten-3: #ecf0f1;
$gray-lighten-4: #F5F6F8;

$base-red: $red-lighen-2;
$base-blue: $blue-lighten-2;
$base-orange: $blue-lighten-2;
$base-green: #89b13f;

$input-color-danger: $red-lighen-2;
$input-color-primary: $blue-lighten-2;
$input-color-warning: $base-orange;
$input-color-success: $base-green;
$input-color-normal: $gray_lighten-3;

$border-radius: 6px;

$secondary: #101010;
$secondary_white: #ffffff;
$secondary_yellow: #0078E6;
$gray: #474747;
$gray_darken: #53565a;
$gray_darken-1: #101010;
$gray_darken-2: #292929;
$success: #389e0d;
$dark: $dark-lighten-0;
$error: #f54b5e;
$warning: $base-orange;
$green: #97D700;
$blue: $blue-lighten-0;
$orange: #F1B434;
$red: $red-lighen-2;
$yellow: #e3e829;
$white: #FFFFFF;
$text_h__primary: #1C7B7F;

/* ------------------------------ 
    THEME COLORS 
------------------------------ */
$theme_primary: $blue-lighten-0;
$theme_secondary: $red-lighen-2;
$theme_tertiary: $warning;

$dark_primary: #000B39;
$light_primary: #BBDEFB;
$primary: #fd8e18;
$text_icons: #FFFFFF;
$accent: #2196F3;
$primary_text: #212121;
$secondary_text: #ffffff;
$tertiary_text: $warning;
$green_text: #85BC20;
$divider: #BDBDBD;
$black: #000000;
$secondary_dark: #1C7B7F;
$transparent: #ffffff00;


/* ------------------------------
    EXPORT VARIABLES TO JS
------------------------------ */
:export {
  primaryColor: $theme_primary;
  secondaryColor: $theme_secondary;
  grayColor: $gray;
  grayDarkenColor: $gray_darken;
};

/* ------------------------------
    WEIGHTS 
------------------------------ */
$font-weights: (
  ) !default;
$font-weights: map-deep-merge(("thin": 100,
      "light": 300,
      "regular": 400,
      "medium": 500,
      "semi-bold": 600,
      "bold": 700,
      "black": 900,
    ),
    $font-weights
);

/* ------------------------------ 
    HEADINGS 
------------------------------ */
$heading: (
  ) !default;
$heading: map-deep-merge(("headline-ad": ("size": 90px,
        "weight": 700,
        "line-height": auto,
        "letter-spacing": -1.5px,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "h1": ("size": 2.8125rem,
        "weight": 700,
        "line-height": auto,
        "letter-spacing": -0.5px,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "h2": ("size": 2.25rem,
        "weight": 600,
        "line-height": auto,
        "letter-spacing": auto,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "h3": ("size": 1.96875rem,
        "weight": 500,
        "line-height": auto,
        "letter-spacing": 0.15px,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "h4": ("size": 1.6875rem,
        "weight": 400,
        "line-height": auto,
        "letter-spacing": 0.1px,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "h5": ("size": 1.40625rem,
        "weight": 400,
        "line-height": auto,
        "letter-spacing": 0.1px,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "subtitle-1": ("size": 18px,
        "weight": 400,
        "line-height": auto,
        "letter-spacing": 0.15,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "subtitle-2": ("size": 16px,
        "weight": 500,
        "line-height": auto,
        "letter-spacing": 0.1,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "body-1": ("size": 18px,
        "weight": 400,
        "line-height": auto,
        "letter-spacing": 0.5,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "body-2": ("size": 16px,
        "weight": 400,
        "line-height": auto,
        "letter-spacing": 0.25,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "button": ("size": 16px,
        "weight": 700,
        "line-height": auto,
        "letter-spacing": 1.25px,
        "font-family": $primary_font,
        "text-transform": uppercase,
      ),
      "caption": ("size": 13px,
        "weight": 400,
        "line-height": auto,
        "letter-spacing": 0.4,
        "font-family": $primary_font,
        "text-transform": false,
      ),
      "overline": ("size": 10px,
        "weight": 500,
        "line-height": auto,
        "letter-spacing": 1.5px,
        "font-family": $primary_font,
        "text-transform": uppercase,
      ),
    ),
    $heading
);

/* ------------------------------ 
    GRID LAYOUT 
------------------------------ */
$gutter-padding-horizontal: 1.5rem;
$gutter-margin-vertical: 1rem;

$sm: 0em;
$md: 34.5625em; // 553px / 16px
$lg: 56.3125em; // 901px / 16px
$xl: 87.5625em; // 1401px / 16px

$width-container-sm: 90%;
$width-container-md: 85%;
$width-container-lg: 123.3rem;
$width-container-xl: 130rem;

.container {
  width: $width-container-sm;
  margin: 0 auto;
  position: relative;

  @include breakpoint(xs) {
    .MuiContainer-root {
      padding: 0 !important;
    }
  }

  @include breakpoint(md) {
    width: 90%;
    max-width: 1168px;
  }

  @include breakpoint(lg) {
    max-width: 150em;
  }

  @include breakpoint(xl) {
    max-width: 150em;
  }

  &__fluid {
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-padding-horizontal;
  margin-right: -$gutter-padding-horizontal;

  [class*="flex-sm"] {
    width: 100%;
    max-width: 100%;
    padding-right: $gutter-padding-horizontal;
    padding-left: $gutter-padding-horizontal;
    min-width: 0.1rem;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 1em;
    }
  }

  .test {
    background-color: #f1b434;
    text-align: center;
    border: 1px solid #000;
  }

  .flex-sm-1,
  .flex-sm-2,
  .flex-sm-3,
  .flex-sm-4,
  .flex-sm-5,
  .flex-sm-6,
  .flex-sm-7,
  .flex-sm-8,
  .flex-sm-9,
  .flex-sm-10,
  .flex-sm-11,
  .flex-sm-12 {
    @include breakpoint(sm) {
      margin-bottom: 2em;
    }
  }

  .flex-sm-1 {
    @include breakpoint(sm) {
      @include grid(12);
    }
  }

  .flex-sm-2 {
    @include breakpoint(sm) {
      @include grid(6);
    }
  }

  .flex-sm-3 {
    @include breakpoint(sm) {
      @include grid(4);
    }
  }

  .flex-sm-4 {
    @include breakpoint(sm) {
      @include grid(3);
    }
  }

  .flex-sm-5 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(math.div(5, 12));
      max-width: percentage(math.div(5, 12));
    }
  }

  .flex-sm-6 {
    @include breakpoint(sm) {
      @include grid(2);
    }
  }

  .flex-sm-7 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(math.div(7, 12));
      max-width: percentage(math.div(7, 12));
    }
  }

  .flex-sm-8 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(math.div(8, 12));
      max-width: percentage(math.div(8, 12));
    }
  }

  .flex-sm-9 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(math.div(9, 12));
      max-width: percentage(math.div(9, 12));
    }
  }

  .flex-sm-10 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(math.div(10, 12));
      max-width: percentage(math.div(10, 12));
    }
  }

  .flex-sm-11 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(math.div(11, 12));
      max-width: percentage(math.div(11, 12));
    }
  }

  .flex-sm-12 {
    @include breakpoint(sm) {
      @include grid(1);
    }
  }

  .flex-md-1,
  .flex-md-2,
  .flex-md-3,
  .flex-md-4,
  .flex-md-5,
  .flex-md-6,
  .flex-md-7,
  .flex-md-8,
  .flex-md-9,
  .flex-md-10,
  .flex-md-11,
  .flex-md-12 {
    @include breakpoint(md) {
      margin-bottom: 2em;
    }
  }

  .flex-md-1 {
    @include breakpoint(md) {
      @include grid(12);
    }
  }

  .flex-md-2 {
    @include breakpoint(md) {
      @include grid(6);
    }
  }

  .flex-md-3 {
    @include breakpoint(md) {
      @include grid(4);
    }
  }

  .flex-md-4 {
    @include breakpoint(md) {
      @include grid(3);
    }
  }

  .flex-md-5 {
    @include breakpoint(md) {
      flex: 0 0 percentage(math.div(5, 12));
      max-width: percentage(math.div(5, 12));
    }
  }

  .flex-md-6 {
    @include breakpoint(md) {
      @include grid(2);
    }
  }

  .flex-md-7 {
    @include breakpoint(md) {
      flex: 0 0 percentage(math.div(7, 12));
      max-width: percentage(math.div(7, 12));
    }
  }

  .flex-md-8 {
    @include breakpoint(md) {
      flex: 0 0 percentage(math.div(8, 12));
      max-width: percentage(math.div(8, 12));
    }
  }

  .flex-md-9 {
    @include breakpoint(md) {
      flex: 0 0 percentage(math.div(9, 12));
      max-width: percentage(math.div(9, 12));
    }
  }

  .flex-md-10 {
    @include breakpoint(md) {
      flex: 0 0 percentage(math.div(10, 12));
      max-width: percentage(math.div(10, 12));
    }
  }

  .flex-md-11 {
    @include breakpoint(md) {
      flex: 0 0 percentage(math.div(11, 12));
      max-width: percentage(math.div(11, 12));
    }
  }

  .flex-md-12 {
    @include breakpoint(md) {
      @include grid(1);
    }
  }

  .flex-lg-1,
  .flex-lg-2,
  .flex-lg-3,
  .flex-lg-4,
  .flex-lg-5,
  .flex-lg-6,
  .flex-lg-7,
  .flex-lg-8,
  .flex-lg-9,
  .flex-lg-10,
  .flex-lg-11,
  .flex-lg-12 {
    @include breakpoint(lg) {
      margin-bottom: 1em;
    }
  }

  .flex-lg-1 {
    @include breakpoint(lg) {
      @include grid(12);
    }
  }

  .flex-lg-2 {
    @include breakpoint(lg) {
      @include grid(6);
    }
  }

  .flex-lg-3 {
    @include breakpoint(lg) {
      @include grid(4);
    }
  }

  .flex-lg-4 {
    @include breakpoint(lg) {
      @include grid(3);
    }
  }

  .flex-lg-5 {
    @include breakpoint(lg) {
      flex: 0 0 percentage(math.div(5, 12));
      max-width: percentage(math.div(5, 12));
    }
  }

  .flex-lg-6 {
    @include breakpoint(lg) {
      @include grid(2);
    }
  }

  .flex-lg-7 {
    @include breakpoint(lg) {
      flex: 0 0 percentage(math.div(7, 12));
      max-width: percentage(math.div(7, 12));
    }
  }

  .flex-lg-8 {
    @include breakpoint(lg) {
      flex: 0 0 percentage(math.div(8, 12));
      max-width: percentage(math.div(8, 12));
    }
  }

  .flex-lg-9 {
    @include breakpoint(lg) {
      flex: 0 0 percentage(math.div(9, 12));
      max-width: percentage(math.div(9, 12));
    }
  }

  .flex-lg-10 {
    @include breakpoint(lg) {
      flex: 0 0 percentage(math.div(10, 12));
      max-width: percentage(math.div(10, 12));
    }
  }

  .flex-lg-11 {
    @include breakpoint(lg) {
      flex: 0 0 percentage(math.div(11, 12));
      max-width: percentage(math.div(11, 12));
    }
  }

  .flex-lg-12 {
    @include breakpoint(lg) {
      @include grid(1);
    }
  }

  .flex-xl-1,
  .flex-xl-2,
  .flex-xl-3,
  .flex-xl-4,
  .flex-xl-5,
  .flex-xl-6,
  .flex-xl-7,
  .flex-xl-8,
  .flex-xl-9,
  .flex-xl-10,
  .flex-xl-11,
  .flex-xl-12 {
    @include breakpoint(xl) {
      margin-bottom: 2em;
    }
  }

  .flex-xl-1 {
    @include breakpoint(xl) {
      @include grid(12);
    }
  }

  .flex-xl-2 {
    @include breakpoint(xl) {
      @include grid(6);
    }
  }

  .flex-xl-3 {
    @include breakpoint(xl) {
      @include grid(4);
    }
  }

  .flex-xl-4 {
    @include breakpoint(xl) {
      @include grid(3);
    }
  }

  .flex-xl-5 {
    @include breakpoint(xl) {
      flex: 0 0 percentage(math.div(5, 12));
      max-width: percentage(math.div(5, 12));
    }
  }

  .flex-xl-6 {
    @include breakpoint(xl) {
      @include grid(2);
    }
  }

  .flex-xl-7 {
    @include breakpoint(xl) {
      flex: 0 0 percentage(math.div(7, 12));
      max-width: percentage(math.div(7, 12));
    }
  }

  .flex-xl-8 {
    @include breakpoint(xl) {
      flex: 0 0 percentage(math.div(8, 12));
      max-width: percentage(math.div(8, 12));
    }
  }

  .flex-xl-9 {
    @include breakpoint(xl) {
      flex: 0 0 percentage(math.div(9, 12));
      max-width: percentage(math.div(9, 12));
    }
  }

  .flex-xl-10 {
    @include breakpoint(xl) {
      flex: 0 0 percentage(math.div(10, 12));
      max-width: percentage(math.div(10, 12));
    }
  }

  .flex-xl-11 {
    @include breakpoint(xl) {
      flex: 0 0 percentage(math.div(11, 12));
      max-width: percentage(math.div(11, 12));
    }
  }

  .flex-xl-12 {
    @include breakpoint(xl) {
      @include grid(1);
    }
  }
}