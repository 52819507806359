@import "../../../styles/variables.scss";
@import "../../../styles/mixins/mixin.scss";

.texture {
  position: absolute;
  right: 0;
  top: 650px;
}

.svg__texture {
  position: absolute;
  width: 100vw;
  max-width: 100%;
  left: 0;
  right: 0;
  transform: translateY(30%);
  display: flex;
  justify-content: flex-end;
  
  img {
    width: 146px;
    @include breakpoint(xs) {
      width: 90px;
    }
  }
}

.title__align {
  align-self: flex-start;
}
.section__content {
  margin-top: 95px !important;
  margin-bottom: 100px !important;
  position: relative;

  &__texture {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 99px;
    margin-top: 20px;
    .texture__image{
      width: 300px;
      height: 100%;
      position: absolute;
      right: 0;
    }
    img {
      width: 146px;
    }
  }

  @include breakpoint(xs) {
    margin-top: 45px !important;
    margin-bottom: 63px !important;
  }

  &__title {
    grid-column: 1/3;
    @include breakpoint(xs) {
      h1 {
        font-size: 26px !important;
      }
    }
  }

  &__img {
    display: flex;
    width: 100%;

    @include breakpoint(xs) {
      display: none;
    }    
    @include breakpoint(md) {
      display: none;
    }
    @include breakpoint(lg) {
      display: block;
    }

    img {
      border-radius: 0px;
      width: 100%;
    }
  }
  &__items {
    display: grid;
    gap: 2rem;
    padding-left: 6rem !important;
    @include breakpoint(xs) {
      padding-left: 1rem !important;
    }
    @include breakpoint(md) {
      padding-left: 0rem !important;
    }
    @include breakpoint(lg) {
      padding-left: 6rem !important;
    }
  } 
}

.section__works {
  // background: url("../../../static/img/texture_points.svg"), $primary;
  background: darken($theme_primary, 10%);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 74px !important;
  padding-bottom: 84px !important;
  @include breakpoint(xs){
    padding-top: 49px !important;
    padding-bottom: 59px !important;
  }

  &__buttons {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, 210px));
    @include breakpoint(xs) {
      gap: 0.5rem;
      grid-template-columns: repeat(auto-fit, minmax(100px, 100%));
    }
  }


  &__title{
    text-align: center;
    @include breakpoint(xs) {
      text-align: left !important;
      font-size: 26px !important;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    &__tabs {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__tab {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      margin: 15px;
      &__content {
        display: flex;
        align-items: flex-end;

        span {
          margin-left: 5px;
        }
      }
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    &__item {
      flex: 0 0 33.333333%;
    }
  }
}

.section__discover {
  background: rgba(9, 178, 156, 0.1);
  padding-top: 77px !important;
  padding-bottom: 77px !important;
  &__sc {
    padding-top: 95px;
    padding-bottom: 77px;
    @include breakpoint(xs) {
      padding-top: 54px;
    }
  }

  @include breakpoint(xs){
    padding-top: 54px !important;
    padding-bottom: 51px !important;
  }

  &__texture {
    position: absolute;
    width: 100vw;
    max-width: 100%;
    left: 0;
    right: 0;
    transform: translateY(122%);
    display: flex;
    justify-content: flex-end;
    @include breakpoint(sm) {
      display: none;
    }
    @include breakpoint(lg) {
      display: flex;
    }
  }
  &__title {
    margin-bottom: 2rem;
    @include breakpoint(xs) {
      margin-bottom: 0;
    }

  }

  &__subtitle {
    white-space: pre-line;
    line-height: 28px;
  }

  &__showmore {
    width: 100%;
    &__button {
      display: flex;
      justify-content: flex-end;
      @include breakpoint(xs) {
        justify-content: center;
        color: $secondary;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 1.33px;
        font-weight: 700;
      }
      @include breakpoint(sm) {
        justify-content: flex-end !important;
      }
      @include breakpoint(lg) {
        justify-content: flex-end !important;
      }
    }
    a {
      text-decoration: none;
    }
  }
}

.section__experiences {
  background: $gray_lighten-1;
  padding-top: 75px;
  padding-bottom: 100px;
  @include breakpoint(sm) {
    padding-bottom: 0px;
  }

  @include breakpoint(xl){
    padding-bottom: 0px;
  }

  @include breakpoint(xs){
    padding-top: 45px;
    padding-bottom: 0px;
  }

  &__subtitle {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
  }
  
  &__tabs {
    margin-bottom: 60px;
    margin-top: 30px;
  }
  &__content {
    position: relative;
    margin-top: 40px;
    padding-bottom: 200px;
    @include breakpoint(xs) {
      padding-bottom: 70px;
      margin-top: -27px;
    }
    @include breakpoint(sm) {
      padding-bottom: 90px;
      margin-top: 0px;
    }
    @include breakpoint(lg) {
      padding-bottom: 200px;
      margin-top: 40px;
    }
    height: 100%;
    &__img {
      position: relative;
      width: 80%;
      img {
        width: 100%;
        border-radius: 20px;
        @include breakpoint(xs) {
          display: none;
        }
        @include breakpoint(sm) {
          display: none;
        }
        @include breakpoint(lg) {
          display: block;
          width: 60%;
        }

      }
    }
    &__card {
      position: absolute;
      border-radius: 20px;
      background: $secondary_white;
      padding: 20px;
      top: 10px;
      left: 439px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background: transparent;
      @include breakpoint(xs) {
        position: relative;
        top: 0;
        left: 0;
        max-height: 500px;
      }
      @include breakpoint(sm) {
        position: relative;
        top: 0;
        left: 0;
        max-width: 700px;
      }
      @include breakpoint(md) {
        position: relative;
        top: 2%;
        left: 0%;
        max-width: 700px;
      }

      @include breakpoint(lg) {
        position: absolute;
        top: -63px;
        left: 38%;
        max-width: 65%;
      }

      @include breakpoint(xl) {
        top: -5%;
      }

    }
  }
}

.section__news_toppadding{
  padding-top: 0 !important;
}
.section__news {
  text-align: center;
  padding-top: 94px;
  padding-bottom: 88px;
  @include breakpoint(xs) {
    padding-bottom: 0 !important;
  }

  &__content {
    @include breakpoint(xs) {
      padding-bottom: 67px !important;
    }
  }

  &__showmore {
    margin-top: 45px !important;
    @include breakpoint(xs) {
      margin-top: 0 !important;
    }
    div {
      text-decoration: none;
      color: $secondary;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.33px;
      cursor: pointer;
    }
  }
  &__title{
    padding-bottom: 32px;
    @include breakpoint(xs) {
      padding-bottom: 0px;
      margin-bottom: 0px !important;
    }
  }
  &__subtitle {
    padding-bottom: 10px;
    @include breakpoint(xs) {
      padding-bottom: 0px;
    }
  }

  @include breakpoint(xs) {
    text-align: left;
    padding-top: 46px;
    padding-bottom: 67px;
  }

  @media (max-width: 768px) {
    display: block;
  }
}

.custom_top{
  margin-top: 1rem;
  @include breakpoint(xs) {
    margin: 2rem 0 !important;
  }
}
