@import "../../styles/variables.scss";

.wrapper{
  padding: 2.5rem 2rem;
  box-shadow: 0px 4px 28px rgba(169, 177, 209, 0.12);
  background: #fff;
  border-radius: 12px;
  width: 100%;
  .input {
    width: 100%;
    border: none;
    border-radius: 6px;
    outline: none;
    padding: 5px 10px;
    font-size: 0.9rem;
    color: #000;
    margin: 0;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 2px 20px #ECF0F1;
    &:focus {
      border-color: aqua;
    }
  }
  table { 
    thead{
      th {
        font-family: $primary_font;
        font-size: 12px;
        font-weight: bold;
        color: $theme_primary;
        letter-spacing: 0.2px;
        padding: 16px 5px;
        text-transform: uppercase;
      }
    }
    tbody {
      tr {
        cursor: default;
        td {
          font-family: $primary_font;
          font-size: 12px;
          letter-spacing: 0.2px;
          padding: 16px 5px;
          &:first-child{
            color: $blue-lighten-3;
          }
        }
      }
    }
  }
}

.pagination {
    cursor: pointer;
    display: flex;
    color: #0033A0;
    font-size: 20px;
    align-items: center;

    &__icon {
        color: $secondary;
    }
    
    p {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
    }

    .next {
        margin-left: -30px;

        @media (min-width: 1440px) {
            margin-left: -10px;
        }
    }
}