@import "../../../styles/variables.scss";

.login__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
    padding: 0.5rem;
    background: #fff;
    border-radius: 50%;
    border: none;
    transition: all 0.2s ease;

    &:hover {
        transform: scale(1.1);
    }

    &:focus {
        outline: none;
    }
}

.login {
    display: flex;
    flex-direction: column;

    &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #fff;
        transition: all 0.2s ease;
    }

    &__title {
        font-family: $primary_font;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        color: $theme_primary;
        text-align: center;
    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        margin-bottom: 10px;
        
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        
            p {
                text-align: center;
                padding: 0 !important;
                margin-bottom: 8px;
                margin-top: 8px;
            }
        }

    }
    
    &__links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $primary;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        button {
            @media (max-width: 600px) {
                width: 100%;
            }
        }
    }

}

.error {
    color: red;
    font-size: 0.8rem;
    font-weight: bold;
}