.div__container__pt {
    padding-top: 5rem
}

.section__content {
    margin: 95px 0 100px 0
}

@include breakpoint(md) {
    .div__container__pt {
        padding-top: 8rem
    }
}