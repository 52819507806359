@import '../../styles/variables.scss';

.title {
    font-family: $primary_font;
    font-size: 3rem;
    font-weight: 700;
    color: $dark-lighten-0;
    margin-bottom: 0.5rem;
}
.divider {
    width: 56px;
    height: 4px;
    background: $primary;
    border-radius: 4px;
}