@import "./../../../styles/variables.scss";

.text__reset-password {
    color: $gray-darken;
    text-align: center;
}

.container__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}