.collapse__container{ width: 100%; }
  .collapse__title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #F6F6F6;
  }
  
  .collapse__chevron{
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .collapse__body{
    padding-top: 20px;
  }

.input {
  width: 100%;
  border: none;
  border-radius: 15px;
  outline: none;
  padding: 5px 10px;
  font-size: 0.9rem;
  color: #000;
  margin: 0;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 20px #ECF0F1;
  &:focus {
    border-color: aqua;
  }
}