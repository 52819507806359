@import "./../../styles/variables.scss";
@import "./../../styles/mixins/mixin.scss";

.header {
  background: $secondary_white;
  width: 100%;
  height: 100%;
  padding: 1rem 1.5rem;
  transition: 0.3s ease all;

  @include breakpoint(md) {
    padding: 1rem 3rem;
  }

  &__content {
    overflow: hidden;
    color: $theme_secondary;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;

    &__logo {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 18px;
      font-weight: map-deep-get($font-weights, "medium") !important;

      &__img {
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;
      }

      &__text {
        color: $secondary;
      }

      &__variant {
        font-size: 18px;
        color: $secondary;
      }
    }

    &__nav {
      &__overlay {
        width: 100%;
        height: 100%;
        background: rgba($color: #000, $alpha: 0.7);
        position: relative;
        z-index: -1;
      }

      top: 0;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 100vh;
      position: fixed;
      display: flex;
      flex-direction: column;
      text-align: center;
      background: $secondary_white;
      transform: translate(0);
      transition: 0.3s ease transform;

      @include breakpoint(md) {
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;

        // margin-bottom: ;
        @include breakpoint(md) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
          // margin-right: calc();
        }

        li {
          &:not(:last-child) {
            @include breakpoint(md) {
              margin-bottom: 0;
            }
          }

          a {
            text-decoration: none;
            color: inherit;
            padding: 0.75rem 1.25rem;
            border-radius: 10%;
            font-weight: map-deep-get($font-weights, "medium") !important;

            &:hover {
              color: $secondary;
              font-weight: map-deep-get($font-weights, "bold") !important;
            }

            &:active {
              color: $secondary;
              font-weight: map-deep-get($font-weights, "bold") !important;
            }
          }
        }
      }

      &.isMenu {
        width: 100%;
        height: 100%;
        transform: translate(100%);
      }
    }

    &__divider {
      border-left: 1px solid #ecf0f1;
      height: 40%;
      margin: 26px 0px 0px 15px;
      display: flex;
      align-items: center;
      position: relative;
    }

    &__search {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      color: $primary;
      padding: 20px;
      font-size: 25px;

      &:hover {
        color: $secondary;
      }
    }

    &__toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: 0.3s ease all;
      position: relative;
      font-size: 20px;

      &:hover {
        color: $secondary;
      }

      @include breakpoint(md) {
        display: none;
      }
    }
  }
}

.buttom {
  overflow: hidden;
  color: $dark_primary;
  margin: 0 auto;
  max-width: 1920px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;

  &__nav {
    background: $secondary;
    padding: 0 1.5rem;
    transition: 0.3s ease all;
    color: $primary;
    width: 100%;
    display: flex;

    @include breakpoint(sm) {
      display: none;
    }

    @include breakpoint(md) {
      display: flex;
    }

    @include breakpoint(lg) {
      display: flex;
    }

    // @include breakpoint(md) {
    //   display: none;
    // }

    // @include breakpoint(xs) {
    //   display: none;
    // }

    &__logo {
      font-weight: 600;
      size: 20px;
      font-family: $primary_font;
      color: $white;
    }

    &__options {
      top: 0;
      right: 100%;
      bottom: 0;
      height: 100vh;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background: rgba($secondary, 0.9);
      backdrop-filter: blur(1px);
      transform: translate(0);
      transition: 0.3s ease transform;
      color: $white;

      @include breakpoint(md) {
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
        }

        li {
          padding: 20px;

          @include breakpoint(md) {
            padding: 1px;
          }

          &:not(:last-child) {
            @include breakpoint(md) {
              margin-bottom: 0;
            }
          }

          a {
            text-decoration: none;
            color: inherit;
            padding: 0.75rem 1.25rem;
            border-radius: 10%;
            font-weight: map-deep-get($font-weights, "medium");

            &:hover {
              font-weight: map-deep-get($font-weights, "bold") !important;
            }

            &:active {
              color: $theme_tertiary;
              font-weight: map-deep-get($font-weights, "bold") !important;
            }
          }
        }
      }

      &__active {
        font-weight: map-deep-get($font-weights, "bold") !important;
        text-decoration-line: underline !important;
      }
    }
  }

  &__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
  }
}

.show__lg__up {
  display: flex !important;

  @include breakpoint(xs) {
    display: none !important;
  }

  @include breakpoint(md) {
    display: flex !important;
  }

  @include breakpoint(lg) {
    display: flex !important;
  }

  &__buttons {
    display: none !important;

    @include breakpoint(md) {
      display: flex !important;
    }
  }
}

.navbar {
  &__xs {
    display: none !important;

    @include breakpoint(xs) {
      display: block !important;
    }

    @include breakpoint(sm) {
      display: block !important;
    }

    @include breakpoint(md) {
      display: none !important;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;

      &__toggle {
        font-size: 36px;
        color: $secondary;
      }
    }

    &__section {
      width: 100%;
      background: $secondary_white;
      color: $primary;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      padding: 11px 208px 9px 40px;

      &__logo {
        color: $primary;
      }
    }

    &__item {
      font-weight: 500;
      color: $primary;
    }

    ul {
      width: 100%;
      padding: 0;

      li {
        cursor: pointer;
        text-align: left !important;
        padding: 12px 81.5px 12px 78px;
        font-size: 15px;

        &:hover {
          background: lighten($theme_secondary, 45%);
          font-weight: 700;
        }

        display: flex;
        align-items: center;
      }
    }

    &__search {
      padding: 12px 81.5px 12px 78px;
      display: flex;
      color: $gray_darken;
    }

    &__icon {
      font-size: 24px;
      margin-right: 15px;
      width: 24px;
      display: flex;
      justify-content: center;
    }

    &__opt {
      &__login {
        color: $secondary;
        text-transform: capitalize;
      }
    }
  }
}