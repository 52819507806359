@import "./../../styles/variables.scss";
@import "./../../styles/mixins/mixin.scss";

.divider {
  border-bottom: 4px solid $dark_primary;
  width: 56px;
  margin-top: 8px;
  margin-bottom: 34px;

  @include breakpoint(xs) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.banner {
  &__container {
    height: 6.5rem;
    width: 100%;
    background: $theme_primary;
    /*linear-gradient(90deg, #15A192 0%, #14234B 100%);*/

    @include breakpoint(xs) {
      height: 5.5rem;
    }
  }

  &__content {
    padding-top: 1.5em;
    padding-left: 1.5em;

    @include breakpoint(xs) {
      padding-top: 1.5rem;
      padding-left: 1.5rem;
    }

    .shadow__text {
      text-shadow: 0px 0px 10px $dark-lighten-0;
    }
  }

  &__centered {
    padding: 81px 0;

    @include breakpoint(xs) {
      padding: 54px 0 !important;
    }
  }

  &__vertical {
    height: 100vh;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    align-items: center;
    justify-items: center;

    &__content {
      height: 100%;
      display: grid;
      align-content: center;
      justify-content: center;
    }
  }

  &__list {
    display: flex;
    justify-items: center;
    align-items: center;

    &__avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      padding: 6px;
      margin: 20px;
      border-radius: 50%;
    }
  }
}

.banner_img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 300px;
  // padding: 0% 4.5%;
  position: relative;

  &__layout {
    position: absolute;
    width: 100%;
    height: 348px;
    z-index: 1;
    background: $theme_primary;
  }

  &__backTo {
    width: 100%;
    padding: 6% 0 2%;

    &__btn {
      display: flex;
      align-items: center;
      color: $white;
    }

    &__label {
      padding: 0 0.5rem;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 1.33px;
      text-transform: uppercase;
    }
  }

  &__title {
    width: 100%;
    padding-left: 3rem;

    @include breakpoint(xs) {
      margin-top: 50px;
      padding-left: 0px;
    }

    @media only screen and (min-width: 992) and (max-width: 1080px) {
      max-width: 400px;
    }

  }

  &__img {
    position: absolute;
    height: 341px;
    width: 593px;
    z-index: 2;
    top: 71px;
    right: 0%;
    border-radius: 0 0 0 0;

    @include breakpoint(xs) {
      height: 150px;
      width: 260px;
      top: 194px;
    }

    @include breakpoint(sm) {
      height: 187px;
      width: 325px;
      top: 225px;
    }

    @include breakpoint(md) {
      height: 226px;
      width: 393px;
      top: 186px;
    }

    @include breakpoint(lg) {
      height: 253px;
      width: 440px;
      top: 159px;
    }

    @include breakpoint(xl) {
      height: 341px;
      width: 593px;
      top: 71px;
    }
  }

  &__footer {
    width: 100%;
    height: 63px;

    @include breakpoint(xs) {
      height: 99px;
    }

    position: absolute;
    bottom: 0px;
    z-index: 1;
  }
}

.banner_apiindividual__layout--height {
  height: 439px;

  img {
    width: 593px;
    height: 432px;

    @include breakpoint(md) {
      height: 332px;
      width: 393px;
    }

    @include breakpoint(lg) {
      height: 332px;
      width: 493px;
    }

    @include breakpoint(lg) {
      width: 593px;
      height: 432px;
    }
  }
}

.banner_custom__layout--height {
  @include breakpoint(xs) {
    height: 290px;
  }
}

.banner_custom__img--dnone {
  @include breakpoint(xsl) {
    display: none;
  }
}

.heroOverlay {
  height: 100%;
  width: 100%;
}

.input__search {
  width: 50%;
}

.width-text-banner {
  width: 500px;
  margin-bottom: 22px;
  margin-top: 45px;

  @include breakpoint(xs) {
    width: 100%;
  }
}