@import "./../../styles/variables.scss";

.accordion {
  width: 100%;
  transition: 0.3s ease all;
  box-shadow: 0px 3px 12px -2px rgb(0 0 0 / 10%);
  @include breakpoint(xs) {
    padding: 0;
  }
  &__head {
    transition: 0.3s ease all;
    background: $white;
    color: $dark_primary;
    border-radius: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 30px;
    position: relative;
    border-top: 1px solid #ECF0F1;
    cursor: pointer;
    &__title {
      font-size: 22px;
      font-weight: 400;
      font-family: $primary_font;
      @include breakpoint(xs) {
        font-size: 18px;
      }
      &__actionbutton {
        font-size: 20px;
      }
    }
  }
  &__body {
    transition: 0.3s ease all;
    width: 100%;
    height: 100%;
    padding: 35px 30px;
    margin-top: -9px;
    border-radius: 0px;
  }
}
  
.active {
  transition: 0.3s ease all;
  background: $white;
  color: $dark-lighten-0;
}

.filter {
  width: 100%;
  margin: 30px 0;
  &__title{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.33px;
    font-family: $primary_font;
    text-transform: uppercase;
    color: $gray_darken;
    cursor: pointer;
  }
  &__body{
    font-size: 16px;
    font-family: $primary_font;
    font-weight: 400;
    &__list {
      list-style: none;
      padding: 0;
      &__item {
        margin: 15px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        &__chip {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          width: 50px;
          height: 29px;
          font-weight: 500;
          font-size: 0.875rem;
          font-family: $primary_font;
          color: $secondary_white;
          border-radius: 0.313rem;
        }
      }
    } 
  }
}

.POST {
  background: $blue !important;
}
.GET {
  background: $green;
}
.PUSH {
  background: $orange;
}
.DEL {
  background: $red;
}

.item__active{
  margin: 15px 0;
  padding-left: 10px;
  font-weight: 700;
  border-left: 2px solid $blue-lighten-2;
  color: $theme_primary;
}


