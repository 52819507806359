.card{
    background-color: #ffffff;
    border-radius: 0;
    overflow: hidden;
    box-sizing: content-box;
    color: #000000;
    width: 23%;

    &__wrapper__subs{
        background: #F9F9F9;
        height: 100%;
        width: 100%;
    }

    &__header{
        text-align: center;
        padding: 1.5rem 0px;

        &__title{
            color: #007AC3;
        }
    }

    &__content{
        padding: 0 1.5rem 1.5rem;
    }
}