@import "../../../styles/variables.scss";

.wrapper_subscriptions__wide {
  &__display{
    display: block;
  }
  @include breakpoint(xsl) {
    &__display{
      display: none;
    }
  }
}

.return {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 64px;

  div {
    color: $theme_secondary;
    font-size: 20px;
  }

  span {
    font-family: $primary_font;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 1.33333px;
    text-transform: uppercase;
    color: $theme_secondary;
  }
}
.back__btn {
  position: absolute;
  top: -42px;
  left: 30px;

  @include breakpoint(xs) {
    left: 0;
  }

  @include breakpoint(sm) {
    left: 0;
  }  

  @include breakpoint(lg) {
    left: 30px;
  }

}

table { 
  thead{
    th {
      font-family: $primary_font;
      font-size: 12px !important;
      font-weight: bold;
      color: $theme_primary !important;
      letter-spacing: 0.2px;
      padding: 16px 5px;
      text-transform: uppercase;
    }
  }
  tbody {
    tr {
      td {
        font-family: $primary_font;
        font-size: 12px;
        letter-spacing: 0.2px;
        padding: 16px 5px;
      }
    }
  }
}

.box__title {
  h1 {
    @include breakpoint(xs) {
      font-size: 2.25rem;
    }
  }
}

.wrapper__filters {
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 3fr;
  align-content: center;
  margin-top: 1.25rem;
  &__search {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include breakpoint(xs) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
      }
      @include breakpoint(md) {
          justify-content: flex-start;
      }
  }
  @include breakpoint(xs) {
      grid-template-columns: repeat(1, 1fr);
  }
  @include breakpoint(md) {
      grid-template-columns: repeat(1, 2fr);
  }
  @include breakpoint(lg) {
      grid-template-columns: repeat(2, 3fr);
  }
  @include breakpoint(xl) {
      grid-template-columns: repeat(2, 3fr);
  }
}

