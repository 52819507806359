@charset "UTF-8";.breadcrumb.component {
    background: transparent
}

.breadcrumb.component:after {
    content: '';
    display: table;
    clear: both
}

.breadcrumb.component.breadcrumb-navigation {
    margin: 0;
    overflow: visible;
    line-height: 1
}

.breadcrumb.component a {
    text-decoration: none;
    font-weight: 400;
    color: #757575
}

.breadcrumb.component ol {
    list-style: none
}

.breadcrumb.component .field-title {
    display: inline-block
}

.breadcrumb.component .separator {
    margin: 0 5px;
    display: inline-block;
    vertical-align: middle
}

.breadcrumb.component li {
    position: relative;
    display: block;
    float: left
}

.breadcrumb.component li>.navigation-title {
    display: inline-block;
    vertical-align: middle
}

.breadcrumb.component li>.navigation-title>a:hover {
    color: #003d61
}

.breadcrumb.component li.last .separator {
    display: none
}

.breadcrumb.component li.item-hide:hover>ol {
    display: none
}

.breadcrumb.component li:hover>ol {
    display: block;
    position: absolute;
    top: 20px;
    left: auto;
    z-index: 100
}

.breadcrumb.component li>ol {
    box-sizing: border-box;
    display: none;
    margin: 0;
    background: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #dadada
}

.breadcrumb.component li>ol li {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #dadada;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    float: none;
    margin: 0;
    padding: 2px 20px;
    width: 100%;
    min-width: 140px;
    cursor: pointer
}

.breadcrumb.component li>ol li .fa {
    float: left
}

.breadcrumb.component li>ol li:hover {
    background: #fff;
    color: #fff
}

.breadcrumb.component li>ol li:hover a {
    color: #fff !important
}

.breadcrumb.component li>ol li:before {
    font-size: 14px;
    font-size: 1.4rem;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    content: "";
    top: 50%;
    left: 2px
}

.breadcrumb.component li>ol li>a {
    display: block;
    padding: 2px 0 2px 10px
}

.breadcrumb.component li>ol li:last-child {
    border-bottom-width: 0
}

.breadcrumb-navigation__item {
    margin-right: 10px
}

.breadcrumb-navigation__item:last-child a.breadcrumb-navigation__link {
    color: #232323;
    pointer-events: none;
    cursor: default
}

.breadcrumb-navigation__item:last-child a.breadcrumb-navigation__link:hover {
    color: #232323;
    text-decoration: none
}

.breadcrumb-navigation__item:first-child:last-child {
    display: none !important
}

@media only screen and (max-width: 786px) {
    .breadcrumb-navigation__item:not(:nth-last-child(2)) {
        display:none !important
    }

    .breadcrumb-navigation__item:nth-last-child(2) {
        padding-left: 14px
    }

    .breadcrumb-navigation__item:nth-last-child(2):before {
        content: '';
        position: absolute;
        top: 45%;
        left: 0;
        text-indent: 0;
        border: solid #232323;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        -webkit-transform: rotate(135deg) translateY(0);
        -ms-transform: rotate(135deg) translateY(0);
        transform: rotate(135deg) translateY(0);
        left: 3px
    }
}

@media only screen and (max-width: 786px) and (max-width:786px) {
    .breadcrumb-navigation__item:nth-last-child(2):before {
        top:6px
    }
}

@media only screen and (max-width: 786px) {
    .breadcrumb-navigation__item:nth-last-child(2) .breadcrumb-navigation__separator:before {
        display:none
    }
}

.breadcrumb-navigation .breadcrumb-navigation__link {
    line-height: 16px;
    font-size: 12px
}

.breadcrumb-navigation a.breadcrumb-navigation__link {
    color: #007ac3
}

.breadcrumb-navigation a.breadcrumb-navigation__link:hover {
    color: #007ac3;
    text-decoration: underline
}

.breadcrumb-navigation__separator {
    text-indent: -9999px;
    position: relative;
    margin: 0 0 0 4px
}

.breadcrumb-navigation__separator:before {
    content: '';
    position: absolute;
    top: 45%;
    left: 0;
    text-indent: 0;
    border: solid #232323;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    -ms-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%)
}

@media only screen and (max-width: 786px) {
    .breadcrumb-navigation__separator:before {
        top:6px
    }
}

.breadcrumb-wrapper {
    position: relative;
    padding: 16px 0 !important;
    padding-top: 0 !important
}

.breadcrumb-wrapper:before {
    content: "";
    display: none;
    height: 1px;
    position: absolute;
    top: 0;
    border-top: 1px solid #dadada;
    z-index: 0;
    left: -webkit-calc((100vw - 100%)/-2);
    left: calc((100vw - 100%)/-2);
    width: 100vw
}

.breadcrumb .breadcrumb-navigation__backtotext {
    display: inline
}

.breadcrumb .breadcrumb.breadcrumb-hide li {
    white-space: nowrap
}

.breadcrumb .breadcrumb.breadcrumb-hide li.item-hide+li.item-hide {
    display: none
}

.breadcrumb .breadcrumb.breadcrumb-hide li.item-hide a {
    display: none
}

.breadcrumb .breadcrumb.breadcrumb-hide li.item-hide:before {
    content: "...";
    display: inline
}

.breadcrumb .breadcrumb.breadcrumb-hide li.hide-full {
    display: none
}

.breadcrumb .breadcrumb.triangle-separator .separator {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0
}

.breadcrumb .breadcrumb.triangle-separator .separator:before {
    content: "";
    display: block;
    font-size: 16px
}