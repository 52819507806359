@import "../../../styles/variables.scss";
@import "../../../styles/mixins/mixin.scss";

.contact {
    padding-top: 120px;
    min-height: 100vh;

    &__iframe {
        border: none;
        width: 100%;
        max-width: 100%;
        height: calc(100vh - 124px);
    }
}
